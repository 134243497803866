<script>
  import {
    required,
    email,
    helpers,
    numeric
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
 import moment from "moment";
  import appConfig from "../../../app.config";

  export default {
    props: ['userr'],
    setup() {
      return {
        v$: useVuelidate()
      };
    },
    page: {
      title: "Register",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    created() {
       if(this.userr != undefined) {
       localStorage.setItem('userref', JSON.stringify({username: this.userr, date: this.timestamp() }))
      console.log(this.user)

       this.$router.push({name: 'Register'});
    }


    this.referrealset();
    },
    components: {},
    data() {
      return {
        user: {
          username: "",
          email: "",
          password: "",
          phone: "",
          confirm_password: "",
          name: "",
        },
        submitted: false,
        refuser: null,
        refavaila: false,

        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
      };
    },

    watch: {
     'user.username': function (val) {
      this.user.username =  val.split(/[^a-z0-9 ]/).join('').trim();


      console.log(this.username);
    },


    'user.phone': function (val) {
      this.user.phone = val.split(/[^0-9 ]/).join('');


      console.log(this.username);
    },
    },
    validations: {
      user: {
         name: {
          required: helpers.withMessage("Name is required", required),
        },
        username: {
          required: helpers.withMessage("Username is required", required),
        },
        email: {
          required: helpers.withMessage("Email is required", required),
          email: helpers.withMessage("Please enter valid email", email),
        },
         phone: {
          required: helpers.withMessage("Phone is required", required),
          email: helpers.withMessage("Please enter valid phone", numeric),
        },
        password: {
          required: helpers.withMessage("Password is required", required),
        },
        confirm_password: {
          required: helpers.withMessage("Confirm Password is required", required),
        },
      },
    },
    computed: {
      notification() {
        return this.$store ? this.$store.state.notification : null;
      },
    },
    methods: {
            timestamp() {
   return moment().format('YYYY-MM-DD HH:mm:ss'); 
  },

referrealset(){


    if (Object.prototype.hasOwnProperty.call(localStorage, 'userref')) {

      var userref = JSON.parse(localStorage.getItem('userref'));  
const pastTime = new Date(userref.date);

const now = new Date();

const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;

const timeDiffInMs = now.getTime() - pastTime.getTime();


if(timeDiffInMs >= sevenDaysInMs){
    localStorage.removeItem('userref');
}else{
   
   this.refuser = userref.username;
   this.refavaila = true;
}
    }
    
    
    }
,

      // Try to register the user in with the email, username
      // and password they provided.
      async tryToRegisterIn() {
        this.submitted = true;
        // stop here if form is invalid
        this.v$.$touch();

               this.tryingToRegister = true;
try {
  
       
   await this.$store.dispatch('register',{
          email: this.user.email,
          password: this.user.password,
          name: this.user.name,
          phone: this.user.phone,
          username: this.user.username,
          refuser: this.refuser,
          password_confirmation: this.user.confirm_password,
        
        });  

   this.$router.push({name: 'dashboard'});
          this.tryingToRegister = false;

  if (data.status == false) {
     return this.authError == data.message;

  }            


     
} catch (error) {
  this.tryingToRegister= false;
    this.isRegisterError = true

    var status = error.response.status;



    if (status == 422) {
       var data = JSON.parse(error.response.data.data)
    var html = [];
    this.load = false;
    for (var k in data) {
        if (Object.prototype.hasOwnProperty.call(data, k)) {
               html.push(data[k][0]);
       

        }
    }
    


    } 
   return this.regError = html;
 
         
    
}

     
    
      },
    },
  }
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logo-light.png" alt="" height="40">
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">Best bill payment in nigeria</p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">

              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Create New Account</h5>
                  <p class="text-muted">Get your free gopay account now</p>
                </div>
                <div class="p-2 mt-4">
                  <form class="needs-validation" @submit.prevent="tryToRegisterIn">
                    <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>Registration
                      successfull.</b-alert>

                    <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>
                
                       <li v-for="data in regError " :key="data">
            {{ data }}
     </li>
                    </b-alert>

                    <div v-if="notification.message" :class="'alert ' + notification.type">

                      {{ notification.message }}
       
   
                    
                    </div>
                    <div class="mb-3">
                      <label for="name" class="form-label">Name <span class="text-danger">*</span></label>
                      <input  type="text" class="form-control" v-model="user.name" :class="{
                    'is-invalid': submitted && v$.user.name.$error,
                  }" id="name" placeholder="Enter name">
                      <div v-if="submitted && v$.user.name.$error" class="invalid-feedback">
                        <span v-if="v$.user.name.required.$message">{{
                    v$.user.name.required.$message
                  }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="useremail" class="form-label">Email <span class="text-danger">*</span></label>
                      <input type="email" class="form-control" v-model="user.email" id="useremail" :class="{
                    'is-invalid': submitted && v$.user.email.$error,
                  }" placeholder="Enter email address">
                      <div v-for="(item, index) in v$.user.email.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="username" class="form-label">Username <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="user.username" :class="{
                    'is-invalid': submitted && v$.user.username.$error,
                  }" id="username" placeholder="Enter username">
                      <div v-if="submitted && v$.user.username.$error" class="invalid-feedback">
                        <span v-if="v$.user.username.required.$message">{{
                    v$.user.username.required.$message
                  }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="phone" class="form-label">Phone <span class="text-danger">*</span></label>
                      <input maxlength="11" type="text" class="form-control" v-model="user.phone" :class="{
                    'is-invalid': submitted && v$.user.phone.$error,
                  }" id="phone" placeholder="Enter phone number">
                      <div v-if="submitted && v$.user.phone.$error" class="invalid-feedback">
                        <span v-if="v$.user.phone.required.$message">{{
                    v$.user.phone.required.$message
                  }}</span>
                      </div>
                    </div>

                    <div class="mb-2">
                      <label for="userpassword" class="form-label">Password <span class="text-danger">*</span></label>
                      <input type="password" class="form-control" v-model="user.password" :class="{
                    'is-invalid': submitted && v$.user.password.$error,
                  }" id="userpassword" placeholder="Enter password">
                      <div v-if="submitted && v$.user.password.$error" class="invalid-feedback">
                        <span v-if="v$.user.password.required.$message">{{
                    v$.user.password.required.$message
                  }}</span>
                      </div>
                    </div>

                    <div class="mb-2">
                      <label for="userpassword" class="form-label">Confirm Password <span
                          class="text-danger">*</span></label>
                      <input type="password" class="form-control" v-model="user.confirm_password" :class="{
                    'is-invalid': submitted && v$.user.password.$error,
                  }" id="userconfirmpassword" placeholder="Enter password">
                      <div v-if="submitted && v$.user.confirm_password.$error" class="invalid-feedback">
                        <span v-if="v$.user.password.required.$message">{{
                    v$.user.confirm_password.required.$message
                  }}</span>
                      </div>
                    </div>



 <div class="mb-2" v-if="refavaila">
                      <label for="userpassword" class="form-label">Referral</label>
                      <input type="text" class="form-control" readonly  v-model="refuser" id="userconfirmpassword" placeholder="Referral">
                      
                    </div>
                    <div class="mb-4">
                      <p class="mb-0 fs-12 text-muted fst-italic">By registering you agree to the Gopay <a href="#"
                          class="text-primary text-decoration-underline fst-normal fw-medium">Terms of Use</a></p>
                    </div>

                    <div class="mt-4">
                      
                      <button class="btn btn-success w-100" type="submit" :disabled="tryingToRegister"><div class="spinner-border spinner-border-sm" role="status" v-if="tryingToRegister"> 
  <span class="sr-only">Loading...</span>
</div>
Sign Up</button>
                    </div>

                   
                  </form>

                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div class="mt-4 text-center">
              <p class="mb-0">Already have an account ? <router-link to="/login"
                  class="fw-semibold text-primary text-decoration-underline"> Signin </router-link>
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">&copy; {{new Date().getFullYear()}} Gopay. Crafted with <i
                  class="mdi mdi-heart text-danger"></i> by Gopay</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>